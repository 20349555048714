import React, { useState, useEffect } from "react";
import { useSpring, animated, config } from "react-spring";
import "./css/AboutUs.css";
import building from "./Images/building.jpg";

const AboutUs = () => {
  const [isVisible, setIsVisible] = useState(false);

  const fadeIn = useSpring({
    opacity: isVisible ? 1 : 0,
    transform: isVisible ? "translateY(0)" : "translateY(50px)",
    config: config.slow,
  });

  useEffect(() => {
    const handleScroll = () => {
      const aboutSection = document.getElementById("about");
      if (aboutSection) {
        const rect = aboutSection.getBoundingClientRect();
        const isVisible = rect.top < window.innerHeight / 2;
        setIsVisible(isVisible);
      }
    };

    window.addEventListener("scroll", handleScroll);
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section className="about-us" id="about">
      <animated.div style={fadeIn} className="about-content">
        <animated.h2 style={fadeIn}>About Us</animated.h2>
        <animated.p style={fadeIn}>
          At SRS DigitalZone, we understand that navigating the world of
          personal finance can be a daunting task. That's why we're here to
          simplify the process and guide you towards financial success. As your
          trusted financial partner, we specialize in providing tailored
          solutions, including the best-suited credit cards, savings accounts,
          demat accounts, and loans that align with your unique needs and
          aspirations.
        </animated.p>
        <animated.p style={fadeIn}>
          <strong>Our Mission: Empowering Your Financial Journey</strong>
        </animated.p>
        <animated.p style={fadeIn}>
          Our mission is to empower individuals and businesses to make informed
          financial decisions that pave the way for a secure and prosperous
          future. We believe that everyone deserves access to personalized
          financial services that not only meet their current requirements but
          also align with their long-term goals.
        </animated.p>
        <animated.p style={fadeIn}>
          <strong>What Sets Us Apart: Your Success, Our Priority</strong>
        </animated.p>
        <animated.ul style={fadeIn}>
          <animated.li style={fadeIn}>
            <strong>Personalized Approach:</strong> We understand that each
            person's financial journey is unique. Our team takes the time to
            understand your individual goals, preferences, and challenges to
            provide personalized financial solutions.
          </animated.li>
          <animated.li style={fadeIn}>
            <strong>Expert Guidance:</strong> Our experienced and knowledgeable
            team of financial experts is dedicated to guiding you through the
            intricacies of credit cards, savings accounts, demat accounts, and
            loans. We strive to ensure that you have a clear understanding of
            your options, empowering you to make confident decisions.
          </animated.li>
          <animated.li style={fadeIn}>
            <strong>Comprehensive Services:</strong> Whether you're looking for
            the best credit card rewards, a high-yield savings account, a
            seamless demat account for your investments, or a tailored loan
            solution, we've got you covered. Our comprehensive range of services
            is designed to meet all your financial needs under one roof.
          </animated.li>
          <animated.li style={fadeIn}>
            <strong>Transparency:</strong> We believe in transparent
            communication and ensure that you have a thorough understanding of
            the terms, benefits, and potential risks associated with each
            financial product. Our goal is to build trust and foster long-term
            relationships with our clients.
          </animated.li>
        </animated.ul>
      </animated.div>
      <animated.div style={fadeIn}>
        <img src={building} className="aboutImage" alt="Company Building" />
      </animated.div>
    </section>
  );
};

export default AboutUs;
