import React from "react";
import "./css/Home.css";
import homeimage from "./Images/home.jpg";
import homeImageMobile from "./Images/home_mbl.jpg";

const Home = () => {
  return (
    <div className="home-container" id="home">
      <picture>
        {/* Load the desktop image for screens larger than 768px */}
        <source media="(min-width: 769px)" srcSet={homeimage} />
        {/* Load the mobile image for screens up to 768px */}
        <img
          src={homeImageMobile}
          alt="Empowering your finance success"
          className="home-image"
        />
      </picture>
    </div>
  );
};

export default Home;
