import React, { useState, useEffect } from "react";
import { useSpring, animated, config } from "react-spring";
import "./css/PartnerSection.css"; // Create this CSS file
import partner1 from "./Images/angel.png";
import partner2 from "./Images/au.png";
import partner3 from "./Images/axis.png";
import partner4 from "./Images/Kotak.png";
import partner5 from "./Images/indus.png";
import partner6 from "./Images/mstock.png";
import partner7 from "./Images/yesbank.png";

const PartnerSection = () => {
  const [isVisible, setIsVisible] = useState(false);

  const fadeIn = useSpring({
    opacity: isVisible ? 1 : 0,
    transform: isVisible ? "translateY(0)" : "translateY(50px)",
    config: config.slow,
  });

  useEffect(() => {
    const handleScroll = () => {
      const partnerSection = document.getElementById("partner");
      if (partnerSection) {
        const rect = partnerSection.getBoundingClientRect();
        const isVisible = rect.top < window.innerHeight / 2;
        setIsVisible(isVisible);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <animated.div style={fadeIn} className="partner-container" id="partner">
      <h2>Our Partners</h2>
      <div className="partner-images">
        <animated.img
          src={partner1}
          alt="Partner 1"
          style={{ height: 150, width: 400, margin: 20 }}
        />
        <animated.img
          src={partner2}
          alt="Partner 2"
          style={{ height: 150, width: 400, margin: 20 }}
        />
        <animated.img
          src={partner3}
          alt="Partner 3"
          style={{ height: 150, width: 400, margin: 20 }}
        />
        <animated.img
          src={partner4}
          alt="Partner 4"
          style={{ height: 150, width: 400, margin: 20 }}
        />
        <animated.img
          src={partner5}
          alt="Partner 5"
          style={{ height: 150, width: 400, margin: 20 }}
        />
        <animated.img
          src={partner6}
          alt="Partner 6"
          style={{ height: 150, width: 400, margin: 20 }}
        />
        <animated.img
          src={partner7}
          alt="Partner 7"
          style={{ height: 150, width: 400, margin: 20 }}
        />
      </div>
    </animated.div>
  );
};

export default PartnerSection;
