import React, { useEffect, useRef } from "react";
import { useSpring, animated } from "react-spring";
import "./css/Services.css";
import creditcardpic from "./Images/creditcard.jpg";
import savingaccountpic from "./Images/saving.jpg";
import dematpic from "./Images/demat.jpg";

const Services = () => {
  const servicesRef = useRef(null);
  const [isInView, setIsInView] = React.useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry], observerInstance) => {
        if (entry.isIntersecting) {
          setIsInView(true);
          observerInstance.unobserve(entry.target); // Stop observing once in view
        }
      },
      {
        root: null, // Use the viewport as the root
        rootMargin: "0px", // No margin
        threshold: window.innerWidth >= 768 ? 0.5 : 0.1, // Trigger when 50% of the element is visible
      }
    );

    if (servicesRef.current) {
      observer.observe(servicesRef.current);
    }

    // Cleanup the observer on component unmount
    return () => observer.disconnect();
  }, []);

  const fade = useSpring({
    opacity: isInView ? 1 : 0,
    from: { opacity: 0 },
    config: { duration: 1000 }, // Adjust the duration as needed
  });

  return (
    <animated.div
      ref={servicesRef}
      style={fade}
      className="services-container"
      id="service"
    >
      <h2 className="services-title">Our Services</h2>
      <div className="service-row">
        <animated.div style={fade} className="service">
          <img
            src={creditcardpic}
            alt="Credit Card"
            style={{ height: 200, width: 300, borderRadius: 10 }}
          />
          <h2>Credit Cards</h2>
          <p style={{ textAlign: "justify" }}>
            We offer a wide range of credit card options to suit your financial
            needs. Explore our credit card offerings and enjoy exclusive
            benefits and rewards.
          </p>
        </animated.div>
        <animated.div style={fade} className="service">
          <img
            src={savingaccountpic}
            alt="Savings Account"
            style={{ height: 200, width: 300, borderRadius: 10 }}
          />
          <h2>Savings Accounts</h2>
          <p style={{ textAlign: "justify" }}>
            Start saving for your future with our savings account options. Our
            accounts provide competitive interest rates and convenient features.
          </p>
        </animated.div>
        <animated.div style={fade} className="service">
          <img
            src={dematpic}
            alt="Demat Account"
            style={{ height: 200, width: 300, borderRadius: 10 }}
          />
          <h2>Demat Accounts</h2>
          <p style={{ textAlign: "justify" }}>
            Simplify your investments with our demat accounts. Trade in stocks
            and securities seamlessly with our secure and efficient demat
            account services.
          </p>
        </animated.div>
      </div>
    </animated.div>
  );
};

export default Services;
