import React from "react";
import "./css/Footer.css";
import TelegramIcon from "@mui/icons-material/Telegram";
import YouTubeIcon from "@mui/icons-material/YouTube";

const Footer = () => {
  const emailLink = "mailto:support@srsdigitalzone.in";

  return (
    <footer className="footer">
      <div
        className="footer-links"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <TelegramIcon />
        <a
          href="https://t.me/+nVUB9Yq-9Vk1ZjY1"
          target="_blank"
          rel="noopener noreferrer"
        >
          Telegram
        </a>
        <YouTubeIcon />
        <a
          href="https://youtube.com/@SRSDigitalZone?si=9aYVtzrOuSyZ63Na"
          target="_blank"
          rel="noopener noreferrer"
        >
          YouTube
        </a>
      </div>
      <div>
        <div className="footer-address">
          <p>
            Phase 8B, Industrial Area, Sector 74,
            <br />
            Sahibzada Ajit Singh Nagar, Punjab 140308
          </p>
        </div>
        <div className="footerEmail">
          {/* Email link */}
          <a href={emailLink}>
            <i className="material-icons">
              {" "}
              <strong>support@srsdigitalzone.in</strong>
            </i>
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
