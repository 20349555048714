import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBPLeSQG_5GJQm9E-M2PYZHO1FwSozPuRk",
  authDomain: "srsdigitalzone.firebaseapp.com",
  projectId: "srsdigitalzone",
  storageBucket: "srsdigitalzone.appspot.com",
  messagingSenderId: "564356625650",
  appId: "1:564356625650:web:76e78b13ba796fab1a4c6b",
  measurementId: "G-VMZQGPZW69",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export default db;
