import React from "react";
import NavBar from "./NavBar";
import Home from "./Home";
import Services from "./Services";
import AboutUs from "./AboutUs";
import JoinUs from "./JoinUs";
import "./css/App.css";
import Footer from "./Footer";
import PartnerSection from "./PartnerSection";

const App = () => {
  return (
    <div className="app">
      <NavBar />
      <Home />
      <Services />
      <JoinUs />
      <AboutUs />
      <PartnerSection />
      <Footer />
    </div>
  );
};

export default App;
