// MobileMenu.js
import React from "react";
import { Link } from "react-scroll";
import "./css/MobileMenu.css";

const MobileMenu = ({ isOpen, closeMobileMenu }) => {
  return (
    <div className={`mobile-menu ${isOpen ? "open" : ""}`}>
      <ul>
        <li>
          <Link
            to="home"
            smooth={true}
            duration={500}
            onClick={closeMobileMenu}
          >
            Home
          </Link>
        </li>
        <li>
          <Link
            to="service"
            smooth={true}
            duration={500}
            onClick={closeMobileMenu}
          >
            Services
          </Link>
        </li>
        <li>
          <Link
            to="join"
            smooth={true}
            duration={500}
            onClick={closeMobileMenu}
          >
            Join Us
          </Link>
        </li>
        <li>
          <Link
            to="about"
            smooth={true}
            duration={500}
            onClick={closeMobileMenu}
          >
            About Us
          </Link>
        </li>
        <li>
          <Link
            to="partner"
            smooth={true}
            duration={500}
            onClick={closeMobileMenu}
          >
            Partners
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default MobileMenu;
