import React, { useState, useEffect } from "react";
import { Link, animateScroll as scroll } from "react-scroll";
import "./css/NavBar.css";
import logo from "./Images/logo.png";
import MobileMenu from "./MobileMenu"; // Import the MobileMenu component
import MenuIcon from "@mui/icons-material/Menu";
import CancelIcon from "@mui/icons-material/Cancel";

const NavBar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [activeSection, setActiveSection] = useState("home");

  const handleScroll = () => {
    const sections = ["home", "service", "join", "about", "partner"];
    const scrollPosition = window.scrollY;

    for (const section of sections) {
      const element = document.getElementById(section);
      if (
        element.offsetTop / 1.3 <= scrollPosition &&
        element.offsetTop + element.offsetHeight / 1.3 > scrollPosition
      ) {
        setActiveSection(section);
        break;
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  const scrollToTop = () => {
    scroll.scrollToTop();
    closeMobileMenu();
  };

  return (
    <nav className={`navbar ${isMobileMenuOpen ? "mobile-menu-open" : ""}`}>
      <img
        src={logo}
        alt="Logo"
        className="logo"
        onClick={scrollToTop}
        style={{ height: 60, width: 120, marginLeft: 10 }}
      />
      <div className={`nav-links ${isMobileMenuOpen ? "mobile-open" : ""}`}>
        <ul>
          <li>
            <Link
              to="home"
              smooth={true}
              duration={500}
              onClick={closeMobileMenu}
              className={activeSection === "home" ? "active" : ""}
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              to="service"
              smooth={true}
              duration={500}
              onClick={closeMobileMenu}
              className={activeSection === "service" ? "active" : ""}
            >
              Services
            </Link>
          </li>
          <li>
            <Link
              to="join"
              smooth={true}
              duration={500}
              onClick={closeMobileMenu}
              className={activeSection === "join" ? "active" : ""}
            >
              Join Us
            </Link>
          </li>
          <li>
            <Link
              to="about"
              smooth={true}
              duration={500}
              onClick={closeMobileMenu}
              className={activeSection === "about" ? "active" : ""}
            >
              About Us
            </Link>
          </li>
          <li>
            <Link
              to="partner"
              smooth={true}
              duration={500}
              onClick={closeMobileMenu}
              className={activeSection === "partner" ? "active" : ""}
            >
              Partners
            </Link>
          </li>
        </ul>
      </div>
      <div className="mobile-menu-icon" onClick={toggleMobileMenu}>
        {isMobileMenuOpen ? (
          <CancelIcon style={{ color: "white" }} />
        ) : (
          <MenuIcon style={{ color: "white" }} />
        )}
      </div>
      <MobileMenu isOpen={isMobileMenuOpen} closeMobileMenu={closeMobileMenu} />
    </nav>
  );
};

export default NavBar;
