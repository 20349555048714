import React, { useState, useEffect, useRef } from "react";
import { useSpring, animated, config } from "react-spring";
import "./css/JoinUs.css";
import db from "./firebase";
import { collection, addDoc } from "firebase/firestore";

const JoinUs = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    age: "",
    gender: "",
    mobileNumber: "",
    dateTime: "", // Add a field for date and time
  });

  const [formErrors, setFormErrors] = useState({
    fullName: "",
    age: "",
    gender: "",
    mobileNumber: "",
  });

  const [springProps, setSpringProps] = useSpring(() => ({
    opacity: 0,
    transform: "translateY(50px)",
    config: config.slow,
  }));

  const joinUsRef = useRef(null);

  useEffect(() => {
    const joinUsRefValue = joinUsRef.current;

    const handleIntersection = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // If the element is in the viewport, trigger the animation
          setSpringProps({ opacity: 1, transform: "translateY(0)" });
          observer.unobserve(entry.target); // Stop observing once animation is triggered
        }
      });
    };
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    if (joinUsRefValue) {
      observer.observe(joinUsRefValue);
    }

    return () => {
      if (joinUsRefValue) {
        observer.unobserve(joinUsRefValue);
      }
    };
  }, [setSpringProps]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = { ...formErrors };

    if (!formData.fullName) {
      newErrors.fullName = "Full Name is required";
      valid = false;
    } else {
      newErrors.fullName = "";
    }

    if (!formData.age) {
      newErrors.age = "Age is required";
      valid = false;
    } else {
      newErrors.age = "";
    }

    if (!formData.gender) {
      newErrors.gender = "Gender is required";
      valid = false;
    } else {
      newErrors.gender = "";
    }

    if (!formData.mobileNumber) {
      newErrors.mobileNumber = "Mobile Number is required";
      valid = false;
    } else {
      newErrors.mobileNumber = "";
    }

    setFormErrors(newErrors);
    return valid;
  };

  const getCurrentDateTime = () => {
    const now = new Date();
    return now.toLocaleString(); // Adjust the format as needed
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return; // Don't submit if there are validation errors
    }

    try {
      // Create a document with the form data
      await addDoc(collection(db, "applications"), {
        fullName: formData.fullName,
        age: formData.age,
        gender: formData.gender,
        mobileNumber: formData.mobileNumber,
        dateTime: getCurrentDateTime(), // Add the current date and time
      });

      alert("Application submitted successfully!");
      // Clear the form after successful submission
      setFormData({
        fullName: "",
        age: "",
        gender: "",
        mobileNumber: "",
        dateTime: "",
      });
    } catch (error) {
      console.error("Error submitting application:", error);
      alert("Application submission failed.");
    }
  };

  return (
    <animated.div
      ref={joinUsRef}
      style={springProps}
      className="join-us-container"
      id="join"
    >
      <h2 className="join-us-title">Join Our Team</h2>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <div className="form-group">
          <label htmlFor="fullName">Full Name:</label>
          <input
            type="text"
            id="fullName"
            name="fullName"
            value={formData.fullName}
            onChange={handleInputChange}
            required
          />
          <div className="error">{formErrors.fullName}</div>
        </div>
        <div className="form-group">
          <label htmlFor="age">Age:</label>
          <input
            type="number"
            id="age"
            name="age"
            value={formData.age}
            onChange={handleInputChange}
            required
          />
          <div className="error">{formErrors.age}</div>
        </div>
        <div className="form-group">
          <label htmlFor="gender">Gender:</label>
          <select
            id="gender"
            name="gender"
            value={formData.gender}
            onChange={handleInputChange}
            required
          >
            <option value="">Select</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Other</option>
          </select>
          <div className="error">{formErrors.gender}</div>
        </div>
        <div className="form-group">
          <label htmlFor="mobileNumber">Mobile Number:</label>
          <input
            type="tel"
            id="mobileNumber"
            name="mobileNumber"
            value={formData.mobileNumber}
            onChange={handleInputChange}
            required
          />
          <div className="error">{formErrors.mobileNumber}</div>
        </div>

        <div className="form-group">
          <button type="submit">Send Application</button>
          <p style={{ color: "gray", fontSize: 14 }}>
            Our dedicated team will be in touch with you shortly
          </p>
        </div>
      </form>
    </animated.div>
  );
};

export default JoinUs;
